<template lang="pug">
  div(v-if="showCheckinGuide")
    .modal-backdrop.fade.show
    .modal.fade.show
      .modal-dialog
        .modal-content
          .modal-body
            .title.text-center.m-2 チェックインの仕方
              button.close(@click="exec()") ×
            .container.px-4
              div
                #panel1.tabPanel.text-center(v-show="currentTab === 1")
                  .text.text-left.py-2 １　岩場の近くに行く
                  img.guide-img(:src="materialURL + '/img/checkinGuide/checkin_guide_img_1.jpg'" style="max-height:144px")
                  .text.text-left.py-2 ２　ヤマラボを開く
                  img.guide-img(:src="materialURL + '/img/checkinGuide/checkin_guide_img_2.jpg'" style="max-height:144px")
                #panel2.text-center(v-show="currentTab === 2")
                  .text.text-left.py-2 ３　右上の「チェックイン」を押す
                  img.guide-img(:src="materialURL + '/img/checkinGuide/checkin_guide_img_3.png'")
                #panel3.text-center(v-show="currentTab === 3")
                  .text.text-left.py-2 ４　チェックインしたい岩場を押す　
                  img.guide-img(:src="materialURL + '/img/checkinGuide/checkin_guide_img_4.png'")
                #panel4.text-center(v-show="currentTab === 4")
                  .text.text-left.py-2 ５　チェックイン完了！
                  img.guide-img(:src="materialURL + '/img/checkinGuide/checkin_guide_img_5.png'")
              .mt-3  
                nav
                  ul.pagination.justify-content-center
                    li.page-item.px-4(:class="{disabled: currentTab == 1}")
                      button.page-link(@click.prevent="currentTab--" :disabled="currentTab == 1") ＜
                    //- li.page-item.disabled
                    //-   a.page-link ページ {{ currentTab }} / {{ 4 }}
                    li.page-item.px-4(:class="{disabled: currentTab == 4}")
                      button.page-link(@click.prevent="currentTab++" :disabled="currentTab == 4") ＞
              .mt-4.text-center
                div.mb-2
                  input#memory.form-check-input(type="checkbox" v-model="removeGuide")
                  label.form-check-label(for="memory") 今後、再度表示しない
                //- button.btn.btn-primary.py-2.px-5.close-botton(@click="$emit('closeDialog')" :disabled="currentTab != 4") とじる
              
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  //データオブジェクト
  data() {
    return {
      materialURL: process.env.VUE_APP_MATERIAL_URL,
      currentTab: 1,
      removeGuide: false,
      showCheckinGuide: false,
    }
  },
  //メソッド
  methods: {
    exec() {
      if(this.removeGuide) {
        localStorage.removeItem("checkin_guide_limit")
      }
      this.showCheckinGuide = false
    }
  },
  //インスタンス作成後
  created() {
    // ログインしていなかった場合処理終了
    if(!this.$user.isLogin) {
      return
    }

    // 表示期限情報の有無確認
    if(!localStorage.checkin_guide_limit) {
      return
    }

    let date = new Date ()

    let year = date.getFullYear() 	// 年
    let month = ('0' + (date.getMonth() + 1)).slice(-2)	// 月
    let day = ('0' + date.getDate()).slice(-2) //日

    let today = year + month + day
    
    // 現在日と表示期限の比較
    // 現在日より小さい場合
    if(today > localStorage.checkin_guide_limit) {
      localStorage.removeItem("checkin_guide_limit")
      return
    }

    // 現在日以上の場合
    //チェックイン履歴の取得
    axios.get(process.env.VUE_APP_API_GATEWAY + '/api-checkin', {
      params: {
        // "rockyId": this.rockyId,
        "targetUserId": this.$user.userId
      },
      headers: {
        'Authorization': this.$user.idToken
      }
    })
      .then(res => {
        if(res.data == null || res.data.length == 0) {
          //チェックイン履歴とれなかった場合
          //現在のブラウザでガイドが表示していなかった場合
          if(!sessionStorage.getItem("isOpen")) {
            sessionStorage.setItem("isOpen","true")
            this.showCheckinGuide = true
          }
        }else {
          //チェックイン履歴ある場合
          localStorage.removeItem("checkin_guide_limit")
        }
      })
      .catch(err => {
        console.log(err.message)
      })
  },
})
</script>

<style lang="stylus" scoped>
.modal
  display block
.guide-img
  // width 100%
  max-height 325px
.close-botton
  border-radious 20px
.title
  font-size 1.4rem
.text
  font-size .9rem

</style>